import React from "react";
import "./App.css";
import { OandMComponent } from "./OandMComponent";

const App: React.FC = () => {

  document.title = "O&M Profiler";

  return (
    <html className="iress-theme-light">
      <head>
      </head>
      <body>
        <div>
          <h2 className="App-header">Pensions and Investments Analysis (O&amp;M Profiler)</h2>
          {<OandMComponent />}
        </div>
      </body>
    </html>
  );
};

export default App;
