import React from "react";

export interface LaunchComponentState {}

// Component to Launch O&M Profiler authenticated and in context.
export class LaunchComponent extends React.Component<
  {
    oAndMUrl: URL | null;
    username: string;
    ssoToken: string;
    clientId: string;
  },
  LaunchComponentState
> {
  render() {
    if (this.props.oAndMUrl === null) {
      console.log("The O&M Profiler URL is not set.")
      return (
        <div hidden></div>
      ); 
    }

    console.log(`this.props.oAndMUrl ${this.props.oAndMUrl.toString()}`);
    console.log(`Username ${this.props.username}`);
    console.log(`SSO Token ${this.props.ssoToken}`);
    console.log(`Client ID ${this.props.clientId}`);

    const launchUrl: string = new URL(
      "sso/default.aspx",
      this.props.oAndMUrl
    ).toString();

    return (
      <form
        id="om-profiler-post-data-form"
        method="post"
        action={launchUrl}
        target="_blank"
        hidden
      >
        {/* Username, SSO token & client ID */}
        <input value={this.props.username} name="userID" />
        <input value={this.props.ssoToken} name="token" />
        <input value={this.props.clientId} name="XplanClientId" />

        {/* SUBMIT button */}
        <button
          id="submit-button-to-launch-o-and-m-profiler"
          type="submit"
          title="Launch O&amp;M Profiler (using submit)"
        >
          Launch O&amp;M Profiler (using submit)
        </button>
      </form>
    );
  }
}
