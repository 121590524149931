import React, { FormEvent } from "react";
import uniqueId from "react-html-id";
import { Input, FormGroup } from "@iress/oui";

export interface UsernameAndPasswordComponentState {
  username: string;
  password: string;
}

export class UsernameAndPassword extends React.Component<
  {
    username: string;
    onUsernameChanged: (username: string) => void;
    onPasswordChanged: (password: string) => void;
    disabled?: boolean;
  },
  UsernameAndPasswordComponentState
> {
  constructor(props: any) {
    super(props);

    // Enable Unique ID support for this class
    uniqueId.enableUniqueIds(this);

    this.state = {
      username: props.username,
      password: "",
    };
  }

  onUsernameChanged = (username: string) => {
    this.props.onUsernameChanged(username);
  };

  onPasswordChanged = (password: string) => {
    this.props.onPasswordChanged(password);
  };

  async componentDidUpdate() {
    // Update the username only if it has changed
    // Don't update the username if it is blank (not sure if we want to blank out the username)
    if (
      this.props.username !== this.state.username &&
      this.props.username !== ""
    ) {
      this.setState({ username: this.props.username });
    }
  }

  render() {
    const { username, password } = this.state;

    return (
      /* Sign in panel */
      <div className="Section-header">
        <FormGroup inline>
          <div className="subheading">O&amp;M Profiler Sign In</div>
          <Input
            className="username"
            value={username}
            onChange={(event: FormEvent<HTMLInputElement>) =>
              this.onTextUsernameChanged(event)
            }
            id={(this as any).getUniqueId("userID")}
            name="userID"
            placeholder="Please enter your O&amp;M Profiler username (usually an email address)"
            required
            disabled={this.props.disabled}
          />
          <Input
            className="password"
            value={password}
            onChange={(event: FormEvent<HTMLInputElement>) =>
              this.onTextPasswordChanged(event)
            }
            type={Input.Type.Password}
            id={(this as any).getUniqueId("password")}
            placeholder="Please enter your O&amp;M Profiler password"
            disabled={this.props.disabled}
          />
        </FormGroup>
      </div>
    );
  }

  // Event handler for when the user edits the user name.
  private onTextUsernameChanged(myEvent: any): void {
    let { username } = this.state;

    const newText = myEvent.target.value;

    if (username !== newText) {
      username = newText;
      this.setState({ username: username });
      this.onUsernameChanged(username);
    }
  }

  // Event handler for when the user edits the password.
  private onTextPasswordChanged(myEvent: any): void {
    let { password } = this.state;

    const newText = myEvent.target.value;

    if (password !== newText) {
      password = newText;
      this.setState({ password: password });
      this.onPasswordChanged(password);
    }
  }
}
