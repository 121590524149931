import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createBrowserHistory } from "history";
import {
  Button,
  ButtonMode,
  FormGroup,
  Fieldset,
  Notification,
  ButtonType,
} from "@iress/oui";
import { isDevelopment } from "./isDevelopment";
import { BffHelper, TokenData } from "./BffHelper";
import { UsernameAndPassword } from "./UsernameAndPassword";
import { DebugComponent } from "./DebugComponent";
import { LaunchComponent } from "./LaunchComponent";

enum SpaState {
  Waiting, // processing taking place, no user input required (this is also the initial state)
  RequestCredentials, // request credentials from the user
  CredentialsAccepted, // user credentials accepted
  Launched, // O&M Profiler has been launched.
}
export interface OandMComponentState {
  state: SpaState;
  error: string | null;
  username: string;
  ssoToken: string;
  processingMessage: JSX.Element | null;
}

export class OandMComponent extends React.Component<{}, OandMComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      error: null,
      state: SpaState.Waiting,
      username: "",
      ssoToken: "",
      processingMessage: <p>Loading...</p>,
    };
  }

  bffHelper: BffHelper | null = null;
  password: string = "";
  clientId: string = "";
  oAndMUrl: URL | null = null;

  async componentDidMount() {

    // Get the Client ID from the URL.
    this.clientId =
      new URLSearchParams(new URL(document.URL).search).get("entityid") || "";
    if (this.clientId.trim() === "") {
      console.log(
        'You must launch the SPA with a client Id. This should be in the form "?entityid=5972".'
      );

      return;
    }

    if (isDevelopment()) {
      console.log("isDevelopment == true");
      // Generate Test Data (in development)

      return;
    }

    this.setupBffHelper();

    if (this.bffHelper) {
      await this.startup(this.bffHelper);
    }
  }

  private setupBffHelper(): void {
    // Get configuration data from Xplan
    const deployConfig = window.deployConfig;

    console.log("Get current location");
    console.log(window.location.href);

    if (deployConfig) {

      console.log(`Bff Url (relative from app.json) = ${deployConfig.bffURL || "?"}`);

      this.bffHelper = new BffHelper(
        "setupBffHelper",
        deployConfig.bffURL || ""
      );
    }
  }

  private async startup(bffHelper: BffHelper): Promise<void> {
    const profilerUrl = await bffHelper.getProfilerUrl();

    if (profilerUrl === null) {
      console.log("Unable to retrieve O&M Profiler Url.");
      this.setState({ error: "Unable to retrieve O&M Profiler Url." });
      return;
    }

    this.oAndMUrl = profilerUrl;

    console.log(`O&M Profiler url = ${profilerUrl.toString}`);

    const loadingMessage = "Loading stored SSO token from Xplan...";
    console.log(loadingMessage);

    await this.GetStoredSSOToken();

    let isTokenValid: boolean = false;

    if (
      this.state.username !== null &&
      this.state.username.trim() !== "" &&
      this.state.ssoToken !== null &&
      this.state.ssoToken.trim() !== ""
    ) {
      const checkingMessage = "Checking if SSO token is valid...";
      console.log(checkingMessage);

      isTokenValid = await bffHelper.checkSSOToken(this.state.ssoToken);
    } else {
      console.log("Skipped token check");
      console.log(`Username ${this.state.username}`);
      console.log(`SSO token ${this.state.ssoToken}`);
    }

    console.log(`isTokenValid ${isTokenValid}`);

    if (isTokenValid) {
      this.setState({
        processingMessage: <p>Launching O&amp;M Profiler</p>,
      });

      // Launch
      this.Launch();

      await this.postLaunch();
    } else {
      // Display username and password
      this.setState({
        state: SpaState.RequestCredentials,
        processingMessage: null,
      });
    }
  }

  private async postLaunch(): Promise<void> {
    this.setState({
      state: SpaState.Launched,
      processingMessage: null,
    });

    console.log("Go Back to Xplan");
    createBrowserHistory().back();
  }

  private async GetStoredSSOToken() {
    console.log("GetStoredSSOToken");

    if (this.bffHelper === null) {
      this.setState({
        processingMessage: null,
        ssoToken: "",
        error: "bffHelper === null",
      });

      return;
    }

    const tokenData: TokenData = await this.bffHelper.loadSSOToken();

    this.setState({
      processingMessage: null,
      username: tokenData.username,
      ssoToken: tokenData.ssoToken,
      error: null,
    });
  }

  render() {
    const {
      error,
      state,
      username,
      ssoToken,
      processingMessage,
    } = this.state;

    const deployConfig = window.deployConfig;

    // Debugger Div (hidden by default)
    const debug = (
      <div hidden>
        <h1>Debugger</h1>
        {deployConfig && deployConfig.bffURL && (
          <DebugComponent
            bffHelper={
              new BffHelper(
                "Debugger",
                deployConfig.bffURL
              )
            }
            clientId={this.clientId}
            OmProfilerUrl={this.oAndMUrl}
          />
        )}
      </div>
    );

    if (error) {
      return <div className="App-progress-message Error">Error: {error}</div>;
    }

    const progressInstance = (
      <div>
      <div className="App-progress-message">
        <div>
          <div>
            <LaunchComponent
              clientId={this.clientId}
              oAndMUrl={this.oAndMUrl}
              username={username}
              ssoToken={ssoToken}
            />
          </div>
          <p className="App-progress-message">{processingMessage}</p>
        </div>
        <CircularProgress />
      </div>
      </div>
    );

    if (state === SpaState.Waiting) {
      return progressInstance;
    }
    if (state === SpaState.Launched) {
      return (
        <div className="ProgressPanel">
          {debug}
          <div className="App-progress-message">
            Returning to Xplan client...
          </div>
        </div>
      );
    }

    return (
      <div>
        {debug}
        <div>
          <LaunchComponent
            clientId={this.clientId}
            oAndMUrl={this.oAndMUrl}
            username={username}
            ssoToken={ssoToken}
          />
        </div>
        <Fieldset id="fieldset-username-password">
          <div>
            <UsernameAndPassword
              username={username}
              onUsernameChanged={(newUsername: string) => {
                this.onUsernameChanged(newUsername);
              }}
              onPasswordChanged={(password: string) => {
                this.onPasswordChanged(password);
              }}
              disabled={state === SpaState.CredentialsAccepted}
            />

            {/* Launch button panel */}
            <div className="Button">
              <FormGroup>
                <table id="table_buttons">
                  <tr>
                    <Button
                      id='button-launch-profiler'
                      mode={ButtonMode.Primary}
                      type={ButtonType.Button}
                      label="Launch O&amp;M Profiler"
                      onClick={() => {
                        this.onLaunchOandM();
                      }}
                      disabled={state === SpaState.CredentialsAccepted}
                    />
                  </tr>
                </table>
                {state === SpaState.CredentialsAccepted && progressInstance}
              </FormGroup>
            </div>
            <div className="Sign-Up">
              No login for O&M Profiler?
              Click <a href="https://www.iress.com/omprofiler">here</a> to find out more
            </div>
          </div>

          {/* SSO token panel & client ID - for development only */}
          <div className="Section-header">
            <FormGroup inline>
              <table id="table_user">
                <tr>
                  <td>
                    <input
                      type="hidden"
                      className="ssoToken"
                      value={ssoToken}
                      id="token"
                      name="token"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="hidden"
                      id="XplanClientId"
                      name="XplanClientId"
                      value={this.clientId}
                    />
                  </td>
                </tr>
              </table>
            </FormGroup>
          </div>
        </Fieldset>
      </div>
    );
  }

  private async onLaunchOandM(): Promise<void> {
    console.log(`onLaunchOandM`);

    const validationMessage = this.validateInput();

    if (validationMessage === null) {
      if (this.bffHelper) {
        const requestMessage = "Requesting new SSO token...";
        console.log(requestMessage);

        this.setState({
          state: SpaState.CredentialsAccepted,
        });

        const newSsoToken = await this.bffHelper.requestSSOTokenFromOandM(
          this.state.username,
          this.password
        );

        console.log(`New SSO token ${newSsoToken}`);

        if (newSsoToken === null || newSsoToken.trim() === "") {
          this.setState({
            state: SpaState.RequestCredentials,
            processingMessage: null,
          });

          const errorMessage =
            "Unable to login to O&M Profiler, please check your username and password.";
          console.log(errorMessage);
          Notification.error(errorMessage);
          return;
        }

        const savingMessage = "Saving new SSO token back to Xplan...";

        console.log(savingMessage);
        this.setState({
          state: SpaState.CredentialsAccepted,
          ssoToken: newSsoToken,
        });

        await this.bffHelper.saveSSOToken(
          this.state.ssoToken,
          this.state.username
        );
      } else {
        console.log("BffHelper === null!!!");
      }

      this.Launch();

      await this.postLaunch();
    } else {
      console.log(`Validation message ${validationMessage}`);
      Notification.error(validationMessage);
    }
  }

  private Launch(): void {
    console.log("Launch O&M Profiler");

    const formId: string = "om-profiler-post-data-form";

    console.log(`Form Id = ${formId}`);

    const form: HTMLFormElement | null = document.querySelector("#" + formId);

    if (form === null) {
      console.log("(form === null)");
    } else {
      const submitButtonId: string = "submit-button-to-launch-o-and-m-profiler";

      console.log(`Submit button Id = ${submitButtonId}`);

      const submitButton = form.querySelector("#" + submitButtonId);

      if (submitButton) {
        console.log("requestSubmit");
        form.requestSubmit(submitButton as HTMLElement);
      } else {
        console.log("Unable to find submit button");
        form.requestSubmit();
      }
    }
  }

  // Validates the user input.
  // If input is valid this will return null, else it will return a message describing the problem.
  private validateInput(): string | null {
    const { username } = this.state;
    if (username.trim() === "") {
      return 'You must enter a value for "O&M Profiler sign in".\r\nThis will be an e-mail address.';
    }

    return null;
  }

  private onUsernameChanged(newUsername: string): void {
    console.log(`Username: ${newUsername}`);

    const { username } = this.state;

    if (newUsername !== username) {
      console.log(`username requires an update`);
      this.setState({ username: newUsername });
    }
  }

  private onPasswordChanged(password: string): void {
    this.password = password;
  }
}
