import React, { FormEvent } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import imageCheck from "./images/check.png";
import imageCross from "./images/cross.png";
import imageQuestion from "./images/question.png";
import uniqueId from "react-html-id";
import {
  Button,
  ButtonMode,
  Input,
  FormGroup,
  Fieldset,
  Notification,
  ButtonType,
} from "@iress/oui";

import { BffHelper, TokenData } from "./BffHelper";
import { UsernameAndPassword } from "./UsernameAndPassword";

export interface DebugComponentState {
  isBusy: boolean;
  error: any;
  result: string | null;
  username: string;
  ssoToken: string;
  isTokenValid: boolean | undefined;
  processingMessage: JSX.Element | null;
  displayProgressIndicator: boolean;
}

export class DebugComponent extends React.Component<
  {
    bffHelper: BffHelper;
    clientId: string;
    OmProfilerUrl: URL | null;
  },
  DebugComponentState
> {
  constructor(props: any) {
    super(props);

    // Enable Unique ID support for this class
    uniqueId.enableUniqueIds(this);

    this.bffHelper = props.bffHelper;

    this.state = {
      isBusy: false,
      error: null,
      result: null,
      username: "",
      ssoToken: "",
      isTokenValid: false,
      processingMessage: <div>Loading connection data.</div>,
      displayProgressIndicator: true,
    };
  }

  bffHelper: BffHelper | null = null;
  password: string = "";

  render() {
    const { isBusy, error, username, ssoToken, isTokenValid } = this.state;

    if (error) {
      return (
        <div className="App-progress-message Error">Error: {error.message}</div>
      );
    }

    if (ssoToken === undefined) {
      return (
        <div className="App-progress-message">No SSO token was returned.</div>
      );
    }

    // Get the Client ID from the URL.
    let clientId: string | null = new URLSearchParams(
      new URL(document.URL).search
    ).get("entityid");

    if (clientId == null) {
      clientId = "";
    }

    if (this.props.OmProfilerUrl === null) {
      return (
        <div>
          O&amp;M Profiler Url is not set
        </div>
      )
    }

    const postUrl = new URL("sso/default.aspx", this.props.OmProfilerUrl).toString();

    console.log(`postUrl ${postUrl}`);

    return (
      <form
        onSubmit={(event: FormEvent<HTMLFormElement>) =>
          this.handleSubmit(event)
        }
        id={(this as any).getUniqueId("om-profiler-post-data-form")}
        method="post"
        action={postUrl}
        target="_blank"
      >
        <Fieldset>
          <UsernameAndPassword
            username={username}
            onUsernameChanged={(newUsername: string) => {
              this.onUsernameChanged(newUsername);
            }}
            onPasswordChanged={(password: string) => {
              this.onPasswordChanged(password);
            }}
          />

          <div className="Section-header"></div>

          {/* SSO token panel & client ID - for development only */}
          <div className="Section-header">
            <FormGroup inline>
              <table id="table_user">
                <tr>
                  <div className="subheading">
                    SSO Token &amp; Client ID (for development only)
                  </div>
                </tr>

                <tr>
                  <td>
                    <Input
                      className="ssoToken"
                      disabled={isBusy}
                      value={ssoToken}
                      onChange={(event: FormEvent<HTMLInputElement>) =>
                        this.onTextSSOTokenChanged(event)
                      }
                      id={(this as any).getUniqueId("token")}
                      name="token"
                      placeholder="SSO Token"
                    />
                  </td>
                  <td>
                    {isBusy && <CircularProgress />}

                    {this.renderTokenIcon(isTokenValid, isBusy)}

                  </td>
                </tr>

                <tr>
                  <th>Xplan Client Id (int)</th>
                  <td>
                    <Input
                      type={Input.Type.Number}
                      id={(this as any).getUniqueId("XplanClientId")}
                      name="XplanClientId"
                      value={clientId}
                    />
                  </td>
                </tr>
              </table>
            </FormGroup>
          </div>
          <div className="Section-header"></div>

          {/* Buttons panel */}
          <div className="Button">
            <FormGroup>
              <table id="table_buttons">
                <tr>
                  <Button
                    id="button-load-sso-token"
                    mode={ButtonMode.Primary}
                    disabled={isBusy}
                    onClick={this.loadSSOTokenHandler}
                    label="Load SSO Token (and username) from Xplan"
                  />

                  <Button
                    id="button-check-sso-token"
                    mode={ButtonMode.Primary}
                    disabled={isBusy}
                    onClick={this.checkSSOTokenHandler}
                    label="Check SSO Token"
                  />
                </tr>

                <tr>
                  <Button
                    id="button-generate-sso-token"
                    mode={ButtonMode.Primary}
                    disabled={isBusy}
                    onClick={this.generateSSOTokenHandler}
                    label="Generate SSO Token"
                  />

                  <Button
                    id="button-save-sso-token"
                    mode={ButtonMode.Primary}
                    disabled={isBusy}
                    onClick={this.saveSSOTokenHandler}
                    label="Save SSO Token (and username) to Xplan"
                  />
                </tr>

                <tr>
                  <Button
                    mode={ButtonMode.Primary}
                    id={(this as any).getUniqueId("submit-button")}
                    disabled={isBusy}
                    type={ButtonType.Submit}
                    label="Launch O&amp;M Profiler (using submit)"
                  />
                </tr>

                {/* <tr>
                    <Button
                      mode={ButtonMode.Primary}
                      disabled={isBusy}
                      onClick={this.launchOandMHandler}
                      label="Launch O&amp;M Profiler (using fetch - not currently working)"
                    />
                  </tr> */}
                <tr>
                  <Button
                    id='button-debug-launch'
                    mode={ButtonMode.Primary}
                    disabled={isBusy}
                    onClick={() => {
                      this.onLaunch();
                    }}
                    label="Launch O&amp;M Profiler (from code)"
                  />
                </tr>
              </table>
            </FormGroup>
          </div>
        </Fieldset>
      </form>
    );
  }

  // returns an Icon to represent the status of the SSO Token.
  private renderTokenIcon(isTokenValid:boolean | undefined, isBusy:boolean) {

    return (
      <div>
    {isTokenValid === true && isBusy === false && (
      <img
        src={imageCheck}
        alt="Token is VALID"
        width="48"
        height="48"
      />
    )}
    {isTokenValid === false && isBusy === false && (
      <img
        src={imageCross}
        alt="Token is INVALID"
        width="48"
        height="48"
      />
    )}
    {isTokenValid === undefined && isBusy === false && (
      <img
        src={imageQuestion}
        alt="Token status is UNKNOWN"
        width="48"
        height="48"
      />)}
            </div>
    );
  }

  private handleSubmit(myEvent: any): void {
    console.log("handleSubmit");

    const { isTokenValid } = this.state;

    if (isTokenValid === false) {
      // don't navigate to O&M
      Notification.error(
        "Unable to launch O&M Profiler, the token is invalid or missing."
      );
      console.log("Invalid token");

      myEvent.preventDefault();
    }
  }

  // Event handler for when the user edits the SSO token.
  private onTextSSOTokenChanged(myEvent: any): void {
    console.log("onTextSSOTokenChanged");

    let { ssoToken } = this.state;
    console.log(`SSO Token ${ssoToken}`);

    const newText = myEvent.target.value;

    if (ssoToken !== newText) {
      ssoToken = newText;
      this.setState({
        ssoToken: ssoToken,
        isTokenValid: undefined,
      });
    }
  }

  private readonly loadSSOTokenHandler = async () => {
    console.log("loadSSOTokenHandler");

    this.setState({
      ssoToken: "",
      isTokenValid: false,
    });

    if (this.bffHelper !== null) {
      this.setState({
        isBusy: true,
      });

      const tokenData: TokenData = await this.bffHelper.loadSSOToken();

      this.setState({
        isBusy: false,
        ssoToken: tokenData.ssoToken,
        username: tokenData.username,
        isTokenValid: undefined,
      });
    }
  };

  private readonly checkSSOTokenHandler = async (): Promise<void> => {
    const { ssoToken } = this.state;

    console.log(`checkSSOTokenHandler - token = ${ssoToken}`);

    if (this.bffHelper !== null) {
      this.setState({ isBusy: true });

      const result = await this.bffHelper.checkSSOToken(ssoToken);

      this.setState({ isBusy: false, isTokenValid: result });
    }
  };

  private readonly generateSSOTokenHandler = async () => {
    console.log("generateSSOTokenHandler");

    this.setState({
      ssoToken: "",
      isTokenValid: false,
    });

    const validationMessage = this.validateInput();
    if (validationMessage === null) {
      if (
        this.bffHelper !== null &&
        this.state.username !== undefined &&
        this.password !== undefined
      ) {
        this.setState({ isBusy: true });

        const ssoToken: string = await this.bffHelper.requestSSOTokenFromOandM(
          this.state.username,
          this.password
        );

        this.setState({ isBusy: false });

        if (ssoToken === "") {
          Notification.error("Unable to generate SSO token");
          this.setState({
            ssoToken: "",
            isTokenValid: false,
          });
        } else {
          this.setState({
            ssoToken: ssoToken,
            isTokenValid: undefined,
          });
        }
      } else {
        console.log("Must specify a username and password");
      }
    } else {
      Notification.error(validationMessage);
    }
  };

  private readonly saveSSOTokenHandler = async () => {
    const { ssoToken, username } = this.state;

    console.log(`saveSSOTokenHandler(${username},${ssoToken})`);

    if (this.bffHelper !== null) {
      if (
        username !== undefined &&
        /\S/.test(username) &&
        /\S/.test(ssoToken)
      ) {
        this.setState({ isBusy: true });

        await this.bffHelper.saveSSOToken(ssoToken, username);

        this.setState({ isBusy: false });
      } else {
        Notification.error("Cannot save without a token and username");
      }
    }
  };


  private onUsernameChanged(newUsername: string): void {
    const { username } = this.state;

    if (newUsername !== username) {
      this.setState({ username: newUsername });
    }
  }

  private onPasswordChanged(password: string): void {
    this.password = password;
  }

  // Validates the user input.
  // If input is valid this will return null, else it will return a message describing the problem.
  private validateInput(): string | null {
    const { username } = this.state;
    if (username.trim() === "") {
      return 'You must enter a value for "O&M Profiler sign in".\r\nThis will be an e-mail address.';
    }

    return null;
  }

  // https://www.techiediaries.com/typescript-formdata-example-queryselector-onsubmit/
  private onLaunch(): void {
    console.log("Launch");

    const formId: string = (this as any).getUniqueId(
      "om-profiler-post-data-form"
    );

    console.log(`Form Id = ${formId}`);

    const form: HTMLFormElement | null = document.querySelector("#" + formId);

    if (form === null) {
      console.log("(form === null)");
    } else {
      form.onsubmit = () => {
        const formData = new FormData(form);

        const ssoToken = formData.get(
          "#" + (this as any).getUniqueId("token")
        ) as string;
        console.log(`SSO token ${ssoToken}`);
        // return false to prevent reload (we NEED reload)
      };

      const submitButtonId: string = (this as any).getUniqueId("submit-button");

      console.log(`Submit button Id = ${submitButtonId}`);

      const submitButton = form.querySelector("#" + submitButtonId);

      if (submitButton) {
        form.requestSubmit(submitButton as HTMLElement);
      } else {
        console.log("Unable to find submit button");
        form.requestSubmit();
      }
    }
  }
}
