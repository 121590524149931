export interface TokenData {
  ssoToken: string;
  username: string;
}

// Helper class to communicate with the BFF.
export class BffHelper {
  relativePath: string;

  constructor(source: string, relativePath: string) {
    this.relativePath = relativePath;    

    console.log(`Source ${source}`);
    console.log(`relativePath ${relativePath}`);
  }

  // Gets the O&M Profiler Url
  public async getProfilerUrl(): Promise<URL | null> {
    const getUrl = this.generateUrl("config");

    console.log(`getProfilerUrl getUrl = ${getUrl}`)

    let profilerUrl = null;

    await fetch(getUrl, {
      method: "GET"
    })
      .then((response) => this.extract200Response(response))
      .then((json) => {
        if (json === null) {
          console.log("Status <> 200; IsValid = False");
          return null;
        } else {
          console.log(`O&M Profiler Url = ${json.oandMProfilerUrl}`);
          profilerUrl = new URL(json.oandMProfilerUrl);
        }
      });

      return profilerUrl;
  }

  // Checks if the O&M SSO Token is valid (expired tokens are regarded as invalid)
  public async checkSSOToken(ssoToken: string): Promise<boolean> {
    const getUrl = this.generateUrl("token/check");

    console.log(`checkSSOToken getUrl = ${getUrl}`)

    let isValid: boolean = false;

    console.log(`checkSSOToken "${ssoToken}"`);

    const headers = new Headers();

    headers.set("token", ssoToken);

    await fetch(getUrl, {
      method: "GET",
      headers: headers
    })
      .then((response) => this.extract200Response(response))
      .then((json) => {
        if (json === null) {
          console.log("Status <> 200; IsValid = False");
          isValid = false;
        } else {
          console.log(`IsValid = ${json.isValid}`);
          isValid = json.isValid;
        }
      });

    return isValid;
  }

  // Requests a new token from O&M Profiler
  public async requestSSOTokenFromOandM(
    username: string,
    password: string
  ): Promise<string> {
    const getUrl = this.generateUrl("token/generate");

    console.log(`requestSSOTokenFromOandM ${getUrl}`);

    console.log(`username ${username}`);

    let newToken = "";

    const headers = new Headers();

    headers.set("Authorization", "Basic " + btoa(`${username}:${password}`));

    await fetch(getUrl, {
      method: "GET",
      headers: headers,
    })
      .then((response) => this.extract200Response(response))
      .then((json) => {
        console.log("Extract New SSO token");
        if (json != null && json.newToken !== undefined) {
          newToken = json.newToken;
        }
        console.log(`newToken = ${newToken}`);
      });

    return newToken;
  }

  // Save an SSO token against a user in Xplan.
  public async saveSSOToken(
    ssoToken: string,
    username: string
  ): Promise<boolean> {
    console.log(`saveSSOToken ${ssoToken}`);

    const postUrl = this.generateUrl("tokenstore");

    console.log(`saveSSOToken ${postUrl}`);

    await fetch(postUrl, {
      method: "POST",
      mode: "cors",
      headers: {
        token: ssoToken,
        username: username,
        "Content-Type": "application/json",
        "X-Forwarded-Host" : window.location.hostname
      },
      credentials: "include",
    }).then((response) => {
      console.log(`Status = ${response.status}`);
      if (response.status === 201) {
        return true;
      }

      console.log("Status <> 201; unable to save token");

      return false;
    });

    return false;
  }

  // Loads an SSO token stored against a user in Xplan.
  public async loadSSOToken(): Promise<TokenData> {

    console.log("loadSSOToken");

    const getUrl = this.generateUrl("tokenstore");

    const returnValue: TokenData = {
      ssoToken: "",
      username: "",
    };

    await fetch(getUrl, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "X-Forwarded-Host" : window.location.hostname
      },
      credentials: "include"
    })
      .then((response) => this.extract200Response(response))
      .then((json) => {
        if (json === null) {
          console.log("Status <> 200; unable to load token");
        } else {
          console.log(`Token = ${json.storedToken}`);
          console.log(`Username = ${json.storedUsername}`);
          returnValue.ssoToken = json.storedToken;
          returnValue.username = json.storedUsername;
        }
      });

    return returnValue;
  }

  // Checks for a 200 response and, if found, returns the json.
  private extract200Response(response: Response) {
    console.log(`Status = ${response.status}`);
    if (response.status === 200) {
      return response.json();
    }

    return null;
  }

  private generateUrl(api : string) : string {
    console.log(`Generating URL for "${api}" using ${window.location.href}`)
    return new URL(`${this.relativePath}/api/${api}`, window.location.href).toString();
  }

}
